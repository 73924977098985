<template>
  <div class="header">
    <!--<div class="topbar">
      <div class="container">
        <div class="d-flex justify-content-between">
          <div>
            Scarica la nostra App! Disponibile su App Store e Play Store
          </div>
          <div>
            <div class="social-icons d-flex">
              <a href="https://www.facebook.com/vinoecioccolato/?locale=it_IT" class="mr-2 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20" viewBox="0 0 512 512">
                  <path
                      d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/>
                </svg>
              </a>
              <a href="https://www.instagram.com/vinoecioccolato/" class="mr-2 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20" viewBox="0 0 448 512">
                  <path
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <section class="top-header">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <div class="logo">
            <router-link to="/">
              <img class="logo-main" :src="'/images/logo.png'" style="max-height: 77px;">
            </router-link>
          </div>  
          <div class="menu d-flex align-items-center">
            <CmsMenu :sidebarmenu="sidebarmenu"/>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<!--div class="col-lg-4">
  <div v-if="menus != null" class="horizontal-menu d-flex">
    <div v-for="(menuItem, index) in menus.menuitems" :key="index" class="menu-item position-relative">
      <router-link v-if="!menuItem.itemschild || menuItem.itemschild.length === 0" :to="menuItem.link"
                   class="nav-link">
        {{ menuItem.name }}
      </router-link>
      <div v-else class="dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
           aria-expanded="false">
          {{ menuItem.name }}
        </a>
        <ul class="dropdown-menu position-absolute">
          <li v-for="(childItem, childIndex) in menuItem.itemschild" :key="childIndex">
            <router-link :to="childItem.link" class="nav-link dropdown-item">{{
                childItem.name
              }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div-->

<script>
import CmsMenu from "@/components/CmsMenu.vue";

export default {
  name: "Header",
  props: ['menus', 'sidebarmenu'],
  data() {
    return {}
  },
  components: {
    CmsMenu,
  },
  beforeMount() {

  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
.logo-main {
  max-width: 70%;
}

.header {
  position: fixed;
  width: 100%;
  background: white;
  z-index: 3;
}

.topbar {
  background-color: #ab2431;
  width: 100%;
  color: #ffffff;
  padding: 5px;
  overflow: hidden;
  font-size: 10px;
  font-weight: bold;
}

.top-header .container .row {
  display: flex;
  align-items: center;
}



.menu-item {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.menu-item:hover .dropdown-menu {
  display: block;
}

.top-menu {
  padding-top: 26px !important;
}

@media (max-width: 768px) {
  .top-menu {
    padding-top: 35px !important;
  }
}

.top-menu svg {
  color: #000;
  font-size: 28px;
}

.nav-link {
  margin-top: 10px;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  color: #000;
}



</style>
