export const BASE_API = "https://app.saturnoretail.com/api/v1/";//per chimate API https://app.bikinilovers.it/api/v1/website https://test.bikinilovers.it/api/v1/website
export const BASE_BACKEND_URL = "https://app.saturnoretail.com/"; //https://app.bikinilovers.it/ https://test.bikinilovers.it/
export const PATH_APP = "https://vinoecioccolato.it/"; //http://app.bikinilovers.it:8888/"; http://http://localhost:5173/ http://test.bikinilovers.it:8888/ https://www.bikinilovers.it/
export const PRODUCTS_NUM = 12;
export const PAYPAL_ACCOUNT = "";//buyers@bikinilovers.it //renzcap-facilitator@gmail.com
export const PAYPAL_URL = ""; //https://www.paypal.com/cgi-bin/webscr https://sandbox.paypal.com/cgi-bin/webscr
export const etoken = "7fdb5dc943b568e8ccc038476a348c4e3cb86bb8";

export const cartIDkey = "cartIDVEC";

export const STRIPE_PUBLIC_KEY = "pk_live_5xUMDambm2X5f78eSHpKJiS100afIlunQw"; //"pk_test_bPHjhQ5JphnZ7PpbSclajVxG00xBVLW5x4"; i coloniali
export const IDSALESPOINT = "a72518b8-10f1-4a0f-8cc6-384568914073";