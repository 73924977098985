<template>
  <section class="products related-products section shopping">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h3 class="text-center title-category text-uppercase">
            {{ filterCategory ? filterCategory.catName : 'Prodotti' }}
          </h3>
        </div>
      </div>
      <div class="row mt-4">
        <!-- Filtri a sinistra -->
        <div class="col-md-4 col-lg-3">
          <!-- Desktop view -->
          <div class="filters-desktop d-none d-md-block">
            <div class="categories-title">
              <h6 class="title-filter">PREZZO</h6>
            </div>
            <div class="price-filter">
              <label for="min-price">Prezzo min:</label>
              <input class="form-control" type="number" id="min-price" min="0" v-model="minprice"
                placeholder="Es. 10" />
            </div>
            <div class="price-filter mt-2">
              <label for="max-price">Prezzo max:</label>
              <input class="form-control mt-1" type="number" id="max-price" min="0" v-model="maxprice"
                placeholder="Es. 100" />
            </div>
            <button class="apply-button mt-4" @click="applyFilters">Applica</button>
            <button class="reset-button mt-4" @click="resetFilters">Reset Filtri</button>
            <!-- Box scrollabile per le categorie -->
            <div class="categories-title mt-4">
              <h6 class="title-filter">CATEGORIE</h6>
            </div>
            <div class="categories-scrollable">
              <div class="categories" v-for="(cat, index) in categories" :key="index">
                <RouterLink :to="'/category/' + cat.slug">
                  <div class="category" :class="{ 'selected-filter': this.idsCategory.includes(cat.id) }"
                       @click="toggleSelectItem(index)">
                    <div v-html="cat.description"></div>
                  </div>
                </RouterLink>
              </div>
            </div>
            <div class="categories-title mt-4" v-show="this.showTags">
              <h6 class="title-filter">Regioni</h6>
            </div>
            <div class="tags-scrollable" v-show="this.showTags">
              <div class="tag" :class="{ 'selected-filter': filterTags.includes(tag.slug) }"
                v-for="(tag, index) in tags01" :key="index" @click="toggleTag(tag)">
                <div>{{ tag.slugDisplay }}</div>
              </div>
            </div>
            <div class="categories-title mt-4" v-show="this.showTags">
              <h6 class="title-filter">Vitigni</h6>
            </div>
            <div class="tags-scrollable" v-show="this.showTags">
              <div class="tag" :class="{ 'selected-filter': filterTags.includes(tag.slug) }"
                v-for="(tag, index) in tags02" :key="index" @click="toggleTag(tag)">
                <div>{{ tag.slugDisplay }}</div>
              </div>
            </div>
          </div>
          <!-- Mobile view -->
          <div class="filters-mobile d-block d-md-none">
            <button class="reset-button mb-3" @click="resetFilters">Reset Filtri</button>
            <button class="btn btn-accordion mb-3" @click="toggleMobileFilters">
              Filtri
            </button>
            <div v-show="mobileFiltersOpen" class="accordion">
              <div class="categories-title">
                <h6 class="title-filter">PREZZO</h6>
              </div>
              <div class="price-filter">
                <label for="min-price">Prezzo min:</label>
                <input class="form-control" type="number" id="min-price" min="0" v-model="minprice"
                  placeholder="Es. 10" />
              </div>
              <div class="price-filter mt-2">
                <label for="max-price">Prezzo max:</label>
                <input class="form-control mt-1" type="number" id="max-price" min="0" v-model="maxprice"
                  placeholder="Es. 100" />
              </div>
              <button class="btn apply-button mt-4" @click="applyFilters">Applica</button>
              <div class="categories-title mt-4">
                <h6 class="title-filter">CATEGORIE</h6>
              </div>
              <div class="categories-scrollable">
                <div class="categories" v-for="(cat, index) in categories" :key="index">
                  <RouterLink :to="'/category/' + cat.slug">
                    <div class="category" :class="{ 'selected-filter': this.idsCategory.includes(cat.id) }"
                         @click="toggleSelectItem(index)">
                      <div v-html="cat.description"></div>
                    </div>
                  </RouterLink>
                </div>
              </div>
              <div class="categories-title mt-4" v-show="this.showTags">
                <h6 class="title-filter">Regioni</h6>
              </div>
              <div class="tags-scrollable" v-show="this.showTags">
                <div class="tag" :class="{ 'selected-filter': filterTags.includes(tag.slug) }"
                  v-for="(tag, index) in tags01" :key="index" @click="toggleTag(tag)">
                  <div>{{ tag.slugDisplay }}</div>
                </div>
              </div>
              <div class="categories-title mt-4" v-show="this.showTags">
                <h6 class="title-filter">Vitigni</h6>
              </div>
              <div class="tags-scrollable" v-show="this.showTags">
                <div class="tag" :class="{ 'selected-filter': filterTags.includes(tag.slug) }"
                  v-for="(tag, index) in tags02" :key="index" @click="toggleTag(tag)">
                  <div>{{ tag.slugDisplay }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Prodotti a destra -->
        <div class="col-sm-12 col-md-8 col-lg-9">
          <div class="row">
            <template v-if="products.length > 0">
              <p class="mb-5">Visualizzazione di {{ products.length }} prodotti su {{ totalProducts }} risultati</p>
              <div class="col-6 col-md-6 col-lg-4 mb-4" v-for="product in products" :key="product.id">
                <SingleProduct :product="product" />
              </div>
            </template>
            <template v-else>
              <div v-if="!spinnerLoading" class="d-flex justify-content-center mt-5 mb-5">
                <p>Non ci sono prodotti</p>
              </div>
            </template>
            <div class="d-flex justify-content-center mt-5 mb-5">
              <div class="spinner spinner-border" role="status" v-if="spinnerLoading">
                <span class="visually-hidden"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import SingleProduct from "@/components/SingleProduct.vue";
import { mapGetters } from "vuex";
import { etoken } from "@/constants/constants.js";
import router from "@/router/index.js";

export default {
  name: "CategoryProducts",
  components: { SingleProduct },
  data() {
    return {
      mobileFiltersOpen: false,
      showTags: false,
      emptyMessage: false,
      idsCategory: this.$route.params.categoryId ? [this.$route.params.categoryId] : [],
      slugCategory: this.$route.params.slugCategory ?? null,
      filterTags: JSON.parse(localStorage.getItem('filterTags')) || [],
      prevRoute: null,
      // filterTags:
      // Contiene gli oggetti completi dei tag selezionati ed è utilizzato 
      // per inviare i dati al backend.
      selectedItems: [],
      selectedCategoryId: null,
      minprice: null,
      maxprice: null,
      tags01: [], // Tag con codice '01'
      tags02: [], // Tag con codice '02'
    };
  },
  computed: {
    category() {
      return this.$store.getters.getCategory;
    },
    start() {
      return this.$store.getters.getStatusStart;
    },
    allTags() {
      return this.$store.getters.getTags;
    },
    searchProducts() {
      return this.$store.getters.getStatusSearchProducts;
    },
    searchTotalProducts() {
      return this.$store.getters.getStatusSearchTotalProducts;
    },
    products() {
      return this.$store.getters.getStatusProducts;
    },
    categories() {
      return this.$store.getters.getCategories;
    },
    totalProducts() {
      return this.$store.getters.getStatusTotalProducts;
    },
    spinnerLoading() {
      return this.$store.getters.getStatusSpinnerLoading;
    },
    filterCategory() {
      return this.$store.getters.getFilterCategory;
    },
  },
  created() {
    this.prevRoute = this.$router.options.history.state.forward;
  },
  beforeMount() {
    this.$store.dispatch("getCategories").then(() => {
      const filteredCat = this.categories.find((el) => el.slug === this.slugCategory);
      if(filteredCat) {
        const categoryId = filteredCat.id;
        if (categoryId) {
          const index = this.categories.findIndex((elem) => elem.id === categoryId);
          if (index !== -1) {
            this.$store.commit("SET_FILTER_CATEGORY", {
              idCat: categoryId,
              catName: this.categories[index].description,
            });
            this.idsCategory = [categoryId];

            // Aggiungi un controllo per il recupero dei tags
            const categoriesWithTags = [
              "Vino Rosso",
              "Spumanti Metodo Classico",
              "Vino Bianco",
              "Vini Dolci e Liquorosi",
              "Spumanti Metodo Charmat / Prosecco",
              "Vino Rosato",
              "Champagne"
            ];

            if (categoriesWithTags.includes(this.categories[index].description)) {
              // Recupera i tags solo quando necessario
              this.$store.dispatch("getTags").then(() => {
                this.divideTags();
                this.showTags = true;
              }).catch(err => {
                console.error("Errore nel recupero dei tags:", err);
                this.showTags = false;
              });
            } else {
              this.showTags = false;
            }
          }
        } else {
          this.$store.commit("SET_FILTER_CATEGORY", null);
          this.showTags = false;
        }
      }else{
        router.push({ name: "not-found", params: { pathMatch: 'not-found' }});
      }
    });
  },
  mounted() {
    //this.idsCategory && this.idsCategory.length > 0 || this.filterTags && this.filterTags.length > 0
    if (!this.prevRoute || !this.prevRoute.includes("/product/") || this.products.length === 0) {
        this.$store.commit("EMPTY_PRODUCTS");
      this.getProducts().then(() => {
        this.scrollTop();
      });
    }
    window.addEventListener("scroll", this.listener);
  },
  unmounted() {
    window.removeEventListener("scroll", this.listener);
  },
  methods: {
    toggleMobileFilters() {
      this.mobileFiltersOpen = !this.mobileFiltersOpen;
    },
    resetFilters() {
      this.maxprice = null;
      this.minprice = null;
      this.filterTags = [];
      localStorage.setItem('filterTags', JSON.stringify(this.filterTags));
      this.getProducts().then(() => {
        this.scrollTop();
      });
    },
    toggleTag(tag) {
      const index = this.filterTags.indexOf(tag.slug); // Confronto con lo slug
      //index rappresenta la posizione dello slug all'interno dell'array filterTags. 
      // Se non è presente, il valore sarà -1.
      if (index === -1) {
        this.filterTags.push(tag.slug); // Aggiungi lo slug a filterTags
      } else {
        this.filterTags.splice(index, 1);  // Rimuovi lo slug da filterTags
      }
      localStorage.setItem('filterTags', JSON.stringify(this.filterTags));
      //console.log("this.filterTags",this.filterTags);
      this.applyFilters();
    },
    toggleSelectItem() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    async applyFilters() {
      this.$store.commit("EMPTY_PRODUCTS");
      await this.getProducts().then(() => {
        this.scrollTop();
      });
    },
    async getProducts() {
      this.$store.dispatch("setSpinnerLoading", true);
      await this.$store.dispatch("getProducts", {
        start: this.start,
        idsCategory: this.idsCategory || null,
        slugsCategory : [this.slugCategory],
        minprice: this.minprice || null,
        maxprice: this.maxprice || null,
        tags: this.filterTags.length > 0 ? this.filterTags : [], // Passa solo gli slug selezionati
      }, {
        headers: {
          "Content-Type": "application/json",
          "e-token": etoken,
        }
      }).finally(() => {
        this.$store.dispatch("setSpinnerLoading", false);
      });
    },

    listener() {
      let scrollTop = document.documentElement.scrollTop;
      let scrollHeight = document.documentElement.scrollHeight;
      let clientHeight = document.documentElement.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 500) {
        if (this.products.length < this.totalProducts) {
          this.$store.dispatch("setSpinnerLoading", true);
          this.$store.dispatch("getProducts", {
            start: this.start,
            idsCategory: this.idsCategory || [],
            slugsCategory : [this.slugCategory],
            tags: this.filterTags || [],
            minprice: this.minprice || null,
            maxprice: this.maxprice || null,
          }).then(() => {
            this.$store.dispatch("setSpinnerLoading", false);
          });
        }
      }
    },
    divideTags() {
      if (this.allTags && this.allTags.length > 0) {
        this.tags01 = this.allTags
          .filter(tag => tag.slug.startsWith('01'))
          .map(tag => {
            return {
              ...tag,  // Mantieni il tag originale
              // Solo per la visualizzazione mette la lettera maiuscola allo 'slug'
              slugDisplay: this.capitalize(tag.slug.slice(3))
            };
          });
        //console.log("this.tags01", this.tags01);
        this.tags02 = this.allTags
          .filter(tag => tag.slug.startsWith('02'))
          .map(tag => {
            return {
              ...tag,
              slugDisplay: this.capitalize(tag.slug.slice(3))
            };
          });
      } else {
        console.warn("Nessun tag disponibile.");
      }
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
  },
};

</script>

<style scoped>
.filters-desktop {
  display: block;
}

.filters-mobile {
  display: none;
}

.accordion {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  /*background-color: #f9f9f9;*/
}

@media (max-width: 768px) {
  .filters-desktop {
    display: none;
  }

  .filters-mobile {
    display: block;
  }
}

.selected-filter {
  padding: 10px;
  border: 2px solid #ab2431 !important;
  font-weight: bold !important;
  border-radius: 6px !important;
}

.reset-button {
  width: 100%;
  background-color: transparent;
  color: black;
  text-align: center;
  font-weight: bold;
  border: none !important;
}

.reset-button:hover {
  color: #f1c67b;
  transform: scale(1.05);
  font-weight: bold;
  cursor: pointer;
}

.apply-button {
  border: none;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  background-color: #ab2431;
  color: white;
  text-align: center;
  font-weight: bold;
}

.apply-button:hover {
  background-color: #744333;
  transform: scale(1.05);
  font-weight: bold;
  cursor: pointer;
}

.form-control {
  height: 35px;
  border: none;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.btn-accordion {
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 5px;
  border: none !important;
  border-radius: 10px;
  background-color: #f1c67b;
  color: white;
  transition: background-color 0.3s, transform 0.3s;
}

.btn-accordion:hover {
  background-color: #deac57;
  color: white;
}

.btn-accordion:active {
  border: none !important;
  background-color: #ab2431 !important;
}


.title-filter {
  font-weight: bold;
  text-align: center;
  padding: 5px;
  border: 1px solid #f1c67b;
  background-color: #f1c67b;
  color: #fff;
  margin-bottom: 30px;
}

.title-category {
  padding: 5px;
  margin-top: 25px;
  border: 1px solid black;
  background-color: #744333;
  color: white;
}

.category,
.tag {
  cursor: pointer;
  padding: 5px;
  background-color: #f8f9fa;
  border-radius: 6px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-weight: 500;
  text-align: center;
}

.category:hover,
.tag:hover {
  background-color: #e2e6ea;
}

.categories-title h5 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.categories-scrollable,
.tags-scrollable {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.categories-scrollable {
  margin-bottom: 20px;
}

/* Responsività mobile */
@media (max-width: 768px) {
  .reset-button {
  width: 100%;
  background-color: transparent;
  color: black;
  text-align: center;
  font-weight: bold;
  border: none !important;
}

.reset-button:hover {
  color: #f1c67b;
  transform: scale(1.05);
  font-weight: bold;
  cursor: pointer;
}

.apply-button {
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  background-color: #ab2431;
  color: white;
  text-align: center;
  font-weight: bold;
  border: none;
}

.apply-button:hover {
  background-color: #744333;
  transform: scale(1.05);
  font-weight: bold;
  cursor: pointer;
}
  .col-md-6 {
    margin-bottom: 20px;
  }
  .categories-scrollable {
  margin-bottom: 5px !important;
}

}
</style>
